<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Body class="relative bg-brand-greyblack-300 font-Abu">
      <div class="z-0 fixed start-0 top-0 w-full h-full gradient"></div>
      <main ref="main" class="relative h-full">
        <slot></slot>
      </main>
    </Body>
  </Html>
</template>

<script setup>
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  // addSeoAttributes: true,
});
</script>

<style lang="scss" scoped>
.gradient {
  background: radial-gradient(
      45.36% 55.36% at 110% 10%,
      rgba(208, 45, 46, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      100% 130% at 100% 0%,
      rgba(208, 45, 46, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      120% 120% at -50% 120%,
      rgba(208, 45, 46, 0.5) -30%,
      rgba(149, 141, 141, 0) 87.68%
    );
}
</style>
